import React from "react";
import content from "../assets/content/partner.json";
import SectionWithImage from "../components/SectionWithImage";
// import ContactForm from "../components/Contact"
import PartnerCarousel from "../components/Partner";
import "../styles/pages/Partner.css";

// Generator function to retrieve partner data
function getSectionWithImageData() {
  return content.SectionWithImageData;
}
function getPartnersData(){
  return content.PartnersData
}
// Main function for the Partner Page
function PartnerPage() {
  const secImgData = getSectionWithImageData();
  const Partners = getPartnersData()
  const firstSection = secImgData[0]
  // const renderImage = (image) => {
  //   if (image != null) {
  //     return <img  src={image} alt="loading" width="100%" height="100%" />;
  //   } else {
  //     return  <div className="partner-page-placeholder-img " />;
  //   }
  // };
  return (
    <main className="home-div">
      <section className="partner-section | flex flex-col gap-200">
          <SectionWithImage
            imageSrc={firstSection.imageSrc}
            imageAlt={firstSection.imageAlt}
            heading={firstSection.heading}
            paragraph={firstSection.paragraph}
            buttons={firstSection.buttons}
            imageOnLeft={firstSection.imageOnLeft}
            imageOnMobileTop={true}
          />
      </section>
      {/* <section >
        <h1>Our Partners</h1>
        <div className="partners-partner-div | flex pt-2">
          {Partners.map((partner,index)=>{
            return(
              <React.Fragment key={index}>
                <a href={partner.link} className="partners-img" >{renderImage(partner.image)}</a>
                </React.Fragment>
            )
          })}
        </div>
      </section> */}
      <section className="mt-500">
        <h1>Support & Guidance</h1>
          <PartnerCarousel partnersList={Partners}/>
      </section>
      <section className="corporate-section mt-500">
      {secImgData.map((element, index) => (
        index === 0 ?null:(

          <SectionWithImage
            key={index}
            imageSrc={element.imageSrc}
            imageAlt={element.imageAlt}
            heading={element.heading}
            subHeading={element.subHeading}
            paragraph={element.paragraph}
            buttons={element.buttons}
            imageOnLeft={element.imageOnLeft}
            imageOnMobileTop={true}
          />
        )
        ))}
      </section>
    </main>
  );
}

export default PartnerPage;
