import React from "react";
import SectionWithImage from "../components/SectionWithImage";
import content from "../assets/content/home.json";
import BlogGrid from "../components/BlogGrid";
import PartnersCarousel from "../components/Partner"
// import ContactForm from "../components/Contact";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import "../styles/pages/Home.css";

/*---------------------data generator functions for resuable componenets-------------------- 
 Note: all generator functions starts with get 
 Description: 
            These function collect data from specific content file (src/assets/content/home.json) and modify
            as per needed.
 
 */

function getSectionWithImageFirstData() {
  const dataDict = content.SectionWithImageFirstData;
  // modifications in content
  const heading = (
    <>
      {dataDict.headingInBlack}
      <span className="text-primary"> {dataDict.headingInBlue} </span>
    </>
  );
  const data = {
    imageSrc: dataDict.imageSrc,
    imageAlt: dataDict.imageAlt,
    heading: heading,
    paragraph: dataDict.paragraph,
    buttons: dataDict.buttons,
    imageOnLeft: dataDict.imageOnLeft,
  };
  return data;
}

function getServicesSectionData() {
  // no data modification is required here so returning row content
  return content.ServicesSectionData;
}

function getSectionWithImageSecondData() {
  return content.SectionWithImageSecondData;
}
function getSectionWithImageThirdData() {
  // no modification here
  return content.SectionWithImageThirdData;
}
// function getNewsSectionData() {
//   // no modification here
//   return content.NewsSectionData.Articals;
// }

function getPartnersData() {
  return content.PartnersData;
}
// -----------------------s pecific componenets of homepage ----------------
function ServiceCart({
  imageSrc,
  heading,
  description,
  buttonText,
  imageOnLeft,
  preDescription
}) {

  const renderImage = () => {
    if (imageSrc) {
      return (
        <div className="cart-image">
          <LazyLoadImage placeholderSrc="shimmer.gif" effect="blur" src={imageSrc} alt="Cart" width="100%" height="100%" />{" "}
        </div>
      );
    } else {
      return <div className="cart-placeholder-image " />;
    }
  };

  return (
    <div className="cart">
      {renderImage()}
      <h2 className="cart-heading">{heading}</h2>
      {preDescription?(
        <pre className="cart-description">{description}</pre>
      ):(
      <p className="cart-description">{description}</p>
      )}
    </div>
  );
}

// function NewsCarousel({ children ,controllerEnabled}) {
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const newsCount = React.Children.count(children);
//   const carouselRef = useRef(null);

//   const handleDotClick = (clickedDot) => {
//     setCurrentIndex(clickedDot.index);
//     if (currentIndex < clickedDot.index) {
//       carouselRef.current.scrollBy({
//         left:
//           carouselRef.current.children[0].offsetWidth *
//           (clickedDot.index - currentIndex),
//         behavior: "smooth",
//       });
//     } else {
//       carouselRef.current.scrollBy({
//         left:
//           -carouselRef.current.children[0].offsetWidth *
//           (currentIndex - clickedDot.index),
//         behavior: "smooth",
//       });
//     }
//   };
//   const handlePrev = () => {
//     setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
//     carouselRef.current.scrollBy({
//       left: -carouselRef.current.children[0].offsetWidth,
//       behavior: "smooth",
//     });
//   };

//   const handleNext = () => {
//     setCurrentIndex((prevIndex) =>
//       prevIndex < newsCount - 1 ? prevIndex + 1 : prevIndex
//     );
//     carouselRef.current.scrollBy({
//       left: carouselRef.current.children[0].offsetWidth,
//       behavior: "smooth",
//     });
//   };

//   return (
//     <div className="news-carousel-container | pt-2">
//       <div className="news-carousel-body">
//         {controllerEnabled&&(
//         <button className="news-carousel-arrow prev" onClick={handlePrev}>
//           <span className="material-symbols-outlined">chevron_left</span>
//         </button>
//         )}
//         <div className=" news-carousel" ref={carouselRef}>
//           {React.Children.map(children, (child, index) => (
//             <div
//               key={index}
//               className={`news-carousel-item ${
//                 index === currentIndex ? "active-news" : ""
//               }`}
//             >
//               {child}
//             </div>
//           ))}
//         </div>
//         {controllerEnabled && (
//         <button className="news-carousel-arrow next" onClick={handleNext}>
//           <span className="material-symbols-outlined">chevron_right</span>
//         </button>

//         )}
//       </div>
//      {controllerEnabled&&(
//        <div className="news-carousel-navigation">
//        <div className="news-carousel-dots">
//          {Array.from({ length: newsCount }).map((_, index) => (
//            <span
//              key={index}
//              className={`news-carousel-dot ${
//                index === currentIndex ? "active-dot" : ""
//              }`}
//              onClick={() => {
//                handleDotClick({ index });
//              }}
//            ></span>
//          ))}
//        </div>
//      </div>
//      )}
//     </div>
//   );
// }
// function NewsCard({ imageSrc, imageAlt, heading, publishDate, summary }) {
//   const renderImage = () => {
//     if (imageSrc) {
//       return (
//         <img
//           className="news-card-image | news-upper"
//           src={imageSrc}
//           alt={imageAlt}
//         />
//       );
//     } else {
//       return <div className="news-placeholder-image | news-upper" />;
//     }
//   };
//   return (
//     <div className="news-card">
//       {renderImage()}
//       <div className="news-content">
//         <h2 className="news-card-heading">{heading}</h2>
//         <div className="news-card-summary-container">
//           <p className="news-card-summary">{summary}</p>
//         </div>
//       </div>
//     </div>
//   );
// }
// function PartnersHome( {Partners}){
//   const renderImage = (image) => {
//     if (image != null) {
//       return <img  src={image} alt="loading" width="100%" height="100%" />;
//     } else {
//       return  <div className="partner-page-placeholder-img " />;
//     }
//   };
//   return (
//     <div className="partners-partner-div | flex pt-2">
//     {Partners.map((partner,index)=>{
//       return(
//         <React.Fragment key={index}>
//           <a href={partner.link} className="partners-img" >{renderImage(partner.image)}</a>
//           </React.Fragment>
//       )
//     })}
//     </div>
//   )
// }
// ---------------------main home componenet ------------------------
function HomePage({popUpOpen}) {
  // generating data for reusable components
  const FirstSecImgData = getSectionWithImageFirstData();
  const ServiceData = getServicesSectionData();
  const SecondSecImgData = getSectionWithImageSecondData();
  const ThirdSecImgData = getSectionWithImageThirdData();
  // const NewsData = getNewsSectionData();
  const Partners = getPartnersData();
  return (
    <main className="home-div ">
      <section >
        <div className="hero-section">
          <SectionWithImage
            imageSrc={FirstSecImgData.imageSrc}
            imageAlt={FirstSecImgData.imageAlt}
            heading={FirstSecImgData.heading}
            paragraph={FirstSecImgData.paragraph}
            buttons={FirstSecImgData.buttons}
            imageOnLeft={FirstSecImgData.imageOnLeft}
            triggerPopUp = {popUpOpen}
            buttonClasses= "hero-section-btns"
            />
          </div>
      </section>
      <section className="services-section">
        <h1>{ServiceData.heading}</h1>
        {/* <span>{ServiceData.headingdescription}</span> */}
        <BlogGrid minPerRow={1} maxPerRow={3} gap="4px">
          {ServiceData.servicesCarts.map((cart, index) => (
            cart.preDescription?(

              <ServiceCart
                key={index}
                imageSrc={cart.imageSrc}
                heading={cart.heading}
                description={cart.description}
                buttonText={cart.buttonText}
                imageOnLeft={cart.imageOnLeft}
                preDescription={true}
              />
              ):(
              <ServiceCart
                key={index}
                imageSrc={cart.imageSrc}
                heading={cart.heading}
                description={cart.description}
                buttonText={cart.buttonText}
                imageOnLeft={cart.imageOnLeft}
              />

            )
          ))}
        </BlogGrid>
      </section>
      <section className="mt-500">
        <h1 className="show-in-mobile"> {SecondSecImgData.headingInBlack}<span className="text-primary"> {SecondSecImgData.headingInBlue} </span>?</h1>
        <SectionWithImage
          imageSrc={SecondSecImgData.imageSrc}
          imageAlt={SecondSecImgData.imageAlt}
          paragraph={SecondSecImgData.paragraph}
          buttons={SecondSecImgData.buttons}
          heading={<h1 className="hide-in-mobile"> {SecondSecImgData.headingInBlack}<span className="text-primary"> {SecondSecImgData.headingInBlue} </span>?</h1>}
          imageOnLeft={SecondSecImgData.imageOnLeft}
          imageOnMobileTop={true}
        />
      </section>
      <section className="mt-500">
        <h1 className="show-in-mobile">{ThirdSecImgData.heading}</h1>
        <SectionWithImage
          imageSrc={ThirdSecImgData.imageSrc}
          imageAlt={ThirdSecImgData.imageAlt}
          paragraph={ThirdSecImgData.paragraph}
          heading={<h1 className="hide-in-mobile">{ThirdSecImgData.heading}</h1>}
          buttons={ThirdSecImgData.buttons}
          imageOnLeft={ThirdSecImgData.imageOnLeft}
          imageOnMobileTop={true}
        />
      </section>
      {/* <section className="news-section mt-500">
        <h1>News</h1>
        <NewsCarousel controllerEnabled={false}>
          {NewsData.map((newsCard, index) => (
            <NewsCard
              key={index}
              imageSrc={newsCard.imageSrc}
              imageAlt={newsCard.imageAlt}
              heading={newsCard.heading}
              publishDate={newsCard.publishDate}
              summary={newsCard.summary}
            />
          ))}
        </NewsCarousel>
      </section> */}
      <section className="mt-500 mb-500">
        <h1>Support & Guidance</h1>
          <PartnersCarousel partnersList={Partners}/>
      </section>
      {/* <section className="home-contact ">
        <h1>Contact Us</h1>
        <div className="mt-500">
          <ContactForm row_count={1}></ContactForm>
        </div>
      </section> */}
    </main>
  );
}

export default HomePage;
