import React from 'react';
import PropTypes from 'prop-types';
import '../styles/components/BlogGrid.css';

const BlogGrid = ({ children, maxPerRow, minPerRow ,gap}) => {
  // Logic 
  /* 
    To calculate max and min elements per row i have divided 80 persent of device width with minPerRow (which is 
      minimun elements per row ) or maxPerRow (Which is maximun elements per row)
      For example:
        If i want minimum two elements then i can divide screen width 
        with 2 (width/2) to get maxWidth of an element
        :-   maxWidth = 100%/2  (result = 50%)
        Now we know that if you want at-leat two element in a row that you can't give them more then 50% of width.
        And Vice versa for the maximun element in row
  
  */
  const screenWidth = 94
  const blogCartCss ={
    maxWidth: `${screenWidth/minPerRow}%`,
    minWidth: `${screenWidth/maxPerRow}%`,
  }
  const blogGridCss = {
    gap
  }
  return (
    <div className={`blog-grid `} style={blogGridCss}>
      {React.Children.map(children, (child, index) => (
        <div className="blog-card" key={index} style={blogCartCss}>
          {child}
        </div>
      ))}
    </div>
  );
};

BlogGrid.propTypes = {
  children: PropTypes.node.isRequired,
  maxPerRow: PropTypes.number,
  minPerRow: PropTypes.number,
};

BlogGrid.defaultProps = {
  maxPerRow: 3, // Default maximum number of carts per row
  minPerRow:1, // Default minimum number of carts per row
};

export default BlogGrid;
